import React from 'react';

const Terms = () => {
    return (
        <div>
            <div className="mx-auto px-6 py-8">
                <h1 className="text-xl lg:text-2xl font-bold mb-4">Terms & Condition</h1>
                <p className="mb-4">
                    This agreement serves a single purpose: to inform users of rules that they must agree to. An Agreement regulating our relationship with regard to the use of CashBack Lelo Platform “App” by you.
                </p>

                <p className="mb-4">
                    Our use of the CashBack Lelo and services and tools are governed by the following Terms of Use as applicable to the CashBack Lelo including the applicable policies which are incorporated herein by way of reference. These terms and conditions including the policies constitute Your binding obligations, with CashBack Lelo.

                    Accessing, browsing or otherwise using the site indicates your agreement to all the terms, So please read the Terms Of Use carefully before proceeding. By impliedly or expressly accepting these Terms of Use, You also accept and agree to be bound by Flipkart Policies ((including but not limited to Privacy Policy available at Privacy) as amended from time to time.
                </p>

                <h2 className="text-xl lg:text-2xl font-bold mb-2">GENERAL</h2>
                <p className="mb-4">
                    Please read these terms and conditions prior to using the products and services offered by Techoze Technologies Pvt. Ltd. (the products and services hereinafter referred as “CashBack Lelo”).If you are accessing CashBack Lelo using our Android mobile application ‘CashBack Lelo’, you also agree to be bound by Google’s Terms of Service. If you do not agree with these terms, please do not use the products and services offered by CashBack Lelo. By using CashBack Lelo product and services, you agree that you have read, acknowledged and confirmed the terms and conditions and agree at all times to be bound by them.

                    If at any given time you are dissatisfied with the CashBack Lelo website and/or our mobile application or with any of the clauses of this “Terms and Conditions” document, kindly stop using the CashBack Lelo website and/or our mobile application.
                </p>

                <h2 className="text-xl lg:text-2xl  font-bold mb-2">INFORMATION SECURITY</h2>
                <p className="mb-4">
                    We take appropriate security measures to protect against unauthorized access to or unauthorized alteration, disclosure or destruction of data. These include internal reviews of our data collection, industry standard storage and processing practices and security measures.
                </p>
                <h2 className="text-xl lg:text-2xl  font-bold mb-2">INTELLECTUAL PROPERTY</h2>

                <p className="mb-4">
                    CashBack Lelo is the sole and exclusive owner of associated Intellectual Property including data, information, techniques, methodologies and materials, copyright, trade-mark, patent, designs, proprietary computer programs and software, source code, object code, rights of priority and trade secret rights, and any other right or interest in relation to the CashBack Lelo services, which under the applicable Indian laws.

                    Modifying, copying, distributing, transmitting, reselling, redistributing, broadcasting, displaying, performing, reproducing, publishing, licensing, framing, transferring, or otherwise using in whole or in part any content, software, or Intellectual Property contained in the products or services or contained on the CashBack Lelo Website or its mobile application obtained from social media sites, or using such information, content, or Intellectual Property in a searchable, machine readable database, removing or obscuring any copyright or other notices or legends contained therein is NOT permitted, except when done with CashBack Lelo’s prior written approval.

                    CashBack Lelo is not bound by any agreement with any of the stores/brands for ensuring security of their intellectual property appearing on our website and/or our mobile application. You hereby agree and undertake to be solely responsible in this regard, in the event of claims or disputes which may arise by such stores/brands alleging infringement of intellectual property by CashBack Lelo. You hereby indemnify and keep indemnified, saved, defended and harmless CashBack Lelo and all persons claiming under CashBack Lelo ('Indemnified Persons'), from and against all losses and/or damages arising as a result of any notices, proceedings, litigation, claims, penalties, demands and costs that may be made and/or raised on the Indemnified Persons by any of the stores/brands or any concerned authority (ies) and/or alleging infringement of their intellectual property contained in these stores/brands.
                </p>



                <h2 className="text-xl lg:text-2xl  font-bold mb-2">LIMITATION OF LIABILITY</h2>

                <p className="mb-4">
                    CashBack Lelo shall not be liable for any losses or damages, direct or indirect, arising out of use of CashBack Lelo services. CashBack Lelo does not endorse any goods/service listed. Customer’s purchases are at their sole discretion. CashBack Lelo does not hold any responsibility of the goods/services purchased by the customer using links provided on CashBack Lelo .

                    Although the information provided to you on this Website and/or our mobile application is obtained or compiled from sources which we believe to be reliable, neither CashBack Lelo, nor any of its subsidiaries, directors, officers, employees guarantee you the accuracy, completeness, comprehensiveness, timeliness or availability of any of the products, deals, services or other content provided through this website and/or our mobile application.

                    CashBack Lelo shall make no guarantees for the uninterrupted and continuous availability of the Products and/or Services or of any specific feature(s) of the Products and/or Services. The Products and/or Services are AS IS and are subject to the disclaimers of warranty and the limitations of liability as found in this agreement and which are subject to testing by the Users before entering into this agreement.
                </p>

                <h2 className="text-xl lg:text-2xl  font-bold mb-2">ELECTRONIC SYSTEM ISSUES</h2>

                <p className="mb-4">
                    Access to CashBack Lelo is through electronic devices. Access to CashBack Lelo may consume bandwidth. Sometimes you may experience difficulty accessing the CashBack Lelo Website and/or our mobile application or communicating with CashBack Lelo through the internet or other electronic or wireless device due to your computer system capacity limitations, dense internet traffic, transmission problems and other such problems.

                    CashBack Lelo cannot not guarantee continuous, uninterrupted or secure access to its Website and/or our mobile application as your uninterrupted and satisfactory use of our Website and/or our mobile application is to a great extent dependent on your internet service provider, Wi-Fi Connection, availability of uninterrupted internet connection on your computer/cellphone or any other device connecting you to our Website and/or our mobile application.CashBack Lelo disclaims any liability arising out of losses incurred due to unauthorized access to such electronic devices used to access our website and/or our mobile application.

                    CashBack Lelo shall also not be liable for any harm whatsoever, caused to your electronic device or loss of any data resulting from the download of data/content infected with viruses. It is recommended that you implement appropriate IT security safeguards (including anti-virus and other security checks) on your computer/cellphone or any other electronic device connecting you to our Website and/or our mobile application to satisfy your requirements as to the safety and reliability of the Content. CashBack Lelo permits customers to post the deals, views, reviews. CashBack Lelo is not responsible for any claims made in such contributions. Also hereby contributor undertakes the ethical responsibility of not posting any content which is not permitted by the law.

                    Forum postings, your profile page, the images you upload to our Website and/or our mobile application etc. content that you store, post, or transmit on or through our Website and/or our mobile application, may be accessed by other users (registered or unregistered), including people not known to you. The purpose of making such content public is to enhance your overall shopping experience and to provide you a platform where users meet and interact on a one to one basis. However, the content/images that you post in discussion forms may be prone to misuse by a party not known to you. CashBack Lelo in this regard fully disclaims any liability(ies) or claim(s) which may arise by use/misuse of such content, by any third party. Kindly exercise care and caution in communicating with other users without assuming that your content will be kept private and only disclose your information to other users that you know to be trustworthy.

                    ‘Cookies’ will be used to enrich your web experience. The use of cookies is very common on the Internet and CashBack Lelo use of cookies is similar to that prevailing in the industry from time to time. Recognition of your computer, by the Web servers, each time you return to our web site and/or our mobile application, including the date and time of your visits is made possible by these ‘Cookies’. The pages viewed, time spent at our website and/or our mobile application, and the websites visited just before and just after our website and/or our mobile application are also recognized. Verification of your registration or password information (in case of registered users) during a particular session so that you do not have to input your password multiple times as you navigate the site is also made possible by these ‘cookies’. In the cookies placed by our website and/or our mobile application, since NO personal information about you is collected and stored, such information cannot be passed on to any third party.

                    CashBack Lelo understands and supports your right to block any unwanted Internet activity, especially that of unscrupulous websites. However, blocking CashBack Lelo ‘cookies’ may disable certain features on our website and/or our mobile application and may make it impossible for you to use certain services available on our Website and/or our mobile application. This may also affect your overall shopping experience with us. By accepting these terms and conditions, customer is authorizing the usage of cookies for CashBack Lelo.

                    CashBack Lelo does not source or sell any goods or services but will only direct you to the web domain of that particular store/brand chosen by you when you use our Website and/or our mobile application. Thereafter, you will be required to follow the instructions on that web page for making the payment through your credit/debit/master card etc. It may happen that your purchase activity be interrupted due to faulty internet connection, slowdowns or capacity limitations in the electronic/mobile device used by you, or problems in the website to which you are re-directed to enable the payment. It is expressly clarified that CashBack Lelo is not and shall not be responsible for any loss, damage or liability incurred by you including but not limited to incorrect or higher amount charged to your account/debited, failure by the respective store/brand to refund any extra amount charged to your account, password invalidated, non-acceptance of card, amount charged or debited more than once, loss or misuse of the Bank account details shared by you on such website due to any reason whatsoever etc issues. Your payment concerning merchandise purchased shall at all times be governed by the terms and conditions of the store/brand from whom such purchase is made and we encourage you to refer to, read and fully understand the same before making a purchase.

                    Additionally, security of your credit/debit/master card, net banking password or other unique payment password, bank account number, or other bank account details vis-a-vis. your purchase order shall also be governed by the terms and conditions of the store/brand from whom you purchase a particular product or service. It is suggested that extra care and caution be exercised before sharing your Bank Account details on any web domain.

                </p>

                <h2 className="text-xl lg:text-2xl  font-bold mb-2">PRICES OF ITEMS</h2>

                <p className='mb-4'>
                    Our Website and/or our mobile application offers services, products and deals at prices which are quoted by the respective stores/brands. CashBack Lelo does not directly service products or sell the same on our Website and/or our mobile application. Thus discounts/sale/ coupons may only apply to those brands/deals who offer their products/brands/deals on sale or discounted prices.

                    Although CashBack Lelo updates its Website and/or our mobile application on a regular basis, we may be unaware of a particular deal which may have been withdrawn by the store/brand. It may happen that the same product, of a brand may be differently priced on our Website and/or our mobile application. It is hereby clarified that CashBack Lelo has in place a robust mechanism to stay abreast of the latest deals, prices, discounts and trends.

                    However, we shall not in any manner be liable for any claims, damages, losses or liabilities which may arise as a result of your purchasing a product for a higher price than that available elsewhere or booking a deal which may have been withdrawn by the store/brand. Your payments and refund policies shall be subject to the terms and conditions of the website they are purchased from. You are advised to undertake reasonable efforts in acquainting yourself with such terms and conditions before purchasing any product deal.
                </p>


                <h2 className="text-xl lg:text-2xl  font-bold mb-2">ADVERTISEMENTS</h2>

                <p className='mb-4'>
                    Advertisements from stores/brands/individuals in CashBack Lelo website are accepted only if they conform to the ethical standards. CashBack Lelo does not verify authenticity of advertiser or the claims made in the Advertisements. CashBack Lelo shall not be responsible or liable for any loss or damage of any kind incurred as the result of your relationship with advertiser.

                    Advertisements of the CashBack Lelo appearing on social networking websites viz. Linkedin, Facebook, Twitter, Youtube, etc. is only a promotional initiative to invite participation and involvement of prospective customers located in different parts of the country. The domain links contained therein may either direct you to our Website and/or our mobile application or request your participation by way of feedback, suggestions, shopping experiences, discussions, views, etc. about various latest deals, best discounts, technology, trends, and similar topics.

                    CashBack Lelo in this regard fully disclaims any liability(ies) or claim(s) which may arise by use/misuse of your feedback, suggestions, views, etc. on any of the aforementioned networking websites or blogs, by any third party whether or not known to CashBack Lelo.

                </p>

                <h2 className="text-xl lg:text-2xl  font-bold mb-2">CONDUCT AND RESPONSIBILITY OF USER</h2>

                <p className='mb-4'>
                    All the Content and the authenticity of such Content posted on our Website and/or our mobile application by its users or transmitted through, or linked with the Service, is the sole responsibility of the person /user from whom such Content has originated. CashBack Lelo cannot control and would not be responsible for any Content posted on the Website by its multi users and visitors.

                    You would be solely responsible for all the Content that you may post and hence undertake and agree that you shall adhere to your ethical responsibility of not posting any offensive, indecent, inaccurate, bogus, misleading or otherwise objectionable Content. Also ensure that to the best of your knowledge, no action, suit, proceeding, claim or investigation has been instituted, initiated or threatened to be instituted or initiated against any Content posted thereby.

                    The practice of checking the Content and approving them before being posted in our website is not being followed by CashBack Lelo , however, the right to delete or move any Content violating the Terms and Conditions or for any other reason deemed appropriate by us, remains with us. Please be aware that we shall not be responsible for any loss, claim, damages or liability incurred by you which may arise by using such Content. User shall NOT post, email, or otherwise make available Content that causes cyber attacks like Denial of service or that which causes Virus attacks; Content employing misleading email addresses, or forged headers or otherwise manipulated identifiers in order to disguise the origin of the Content; Content prohibited by Law; Content that infringes any Patent, Trademark, Trade Secret, Copyright or other proprietary rights of any party; Content that is False, Deceptive, Misleading, Deceitful, misinformative , Degrading, Intimidating, Harassing, or is Hateful towards an Individual or Group of Individuals on the basis of Religion, Gender, Sexual Orientation, Race, Ethnicity, Age, or Disability.

                    When starting a new discussion, user to check that there is no duplication of an existing discussion thread or creation of one that is very similar. User shall not resort to automated means to download data from the service, unless permitted by CashBack Lelo; shall not resort to Hacking and shall not interfere with any other party's use and enjoyment of CashBack Lelo Website and/or our mobile application; shall not create links to our website and/or our mobile application from any other website, without our prior written consent of CashBack Lelo; shall not misuse, replicate or copy the Source Code and the Object Code contained in the Computer Program of the CashBack Lelo Website. User shall not do anything that is not permitted by the clauses of this "Terms and Conditions" Document.
                </p>

                <h2 className="text-xl lg:text-2xl  font-bold mb-2">COMPLAINTS AND DEFAMATION</h2>
                <p className='mb-4'>
                    CashBack Lelo is a shopping application driven by the shopping community. Users are encouraged to participate in discussions to share their views, ideas, updates, trends and shopping experiences and benefit from the variety of discounts, deals and coupons offered. Your usage of this Website and/or our mobile application shall be governed by the “Terms and Conditions” contained herein and policies/conditions (if any) of the page that you may visit. Your personal information shall be protected by us in terms of the Privacy Policy of CashBack Lelo . If you have any complaints regarding our Website and/or our mobile application or any content or products/services/deals offered or any other matter, you may write to us at info@thedealapp.com

                    You hereby undertake to refrain from falsely defaming, insulting or offending CashBack Lelo or a store/brand with the intention of falsely causing harm to the reputation of CashBack Lelo or such store/brand on discussion forums or during your interaction with users or visitors. You may note that defamation is a criminal offence under the provisions of the Indian Penal Code and you may be liable for proceedings under the same, if in the opinion of CashBack Lelo or the store/brand, as the case may be, your post, suggestion, feedback, comment or advice on the Website and/or our mobile application amounts to an intention to falsely defame such party.

                    You hereby indemnify CashBack Lelo and keep indemnified, saved, defended and harmless on full indemnity basis CashBack Lelo and all persons claiming under CashBack Lelo , from and against all losses and/or damages arising to CashBack Lelo as a result of any notices, proceedings, litigation, claims, penalties, demands, made by or served upon CashBack Lelo by the respective store/brand and/or judgments, liabilities, costs and expenses (including, without limitation, reasonable attorneys' fees) allegedly arising in that regard, in whole or in part from any user attempting to defame, insult or offend any store/brand or by posting any content which is abusive defamatory or libellous for such store/brand.
                </p>



                <h2 className="text-xl lg:text-2xl  font-bold mb-2">TERMINATION</h2>
                <p className='mb-4'>
                    At any given time, in case any of the terms and conditions in this "Terms and Conditions" document are breached by you, all rights granted to you as per this "Terms and Conditions" will be terminated immediately.

                    Your use of this Website and/or our mobile application indicates to us that you have read and accepted our terms and conditions, as outlined in this document
                </p>

                <h2 className="text-xl lg:text-2xl  font-bold mb-2">CHANGES</h2>
                <p className='mb-4'>
                    CashBack Lelo may make changes to these terms and conditions from time to time with or without notice. When these changes are made, any new terms and conditions will be made available to you. You understand and agree that if you use the product and services after the date on which these terms and conditions have changed, CashBack Lelo will treat your use as acceptance of the updated terms and conditions.

                    You can determine when this version of the “Terms and Conditions” was adopted by referring to the "Last Updated Date" above. You are requested to kindly review the Terms and Conditions, and become familiar with it. A few sections of CashBack Lelo may have specific terms and conditions. In case of conflict, specific terms and conditions govern.

                    All the copyrights of this product are reserved with CashBack Lelo. CashBack Lelo reserves the right to Change or terminate the Products and/or the Services.
                </p>

            </div>
        </div>
    );
};

export default Terms;

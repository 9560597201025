/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Button, CircularProgress, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  AccessTimeOutlinedIcon,
  CategoryIcon,
  CategoryOutlinedIcon,
  StorefrontIcon,
  ViewCompactAltOutlinedIcon,
} from "../../Icons";
import moment from "moment";
import { getAllProducts } from "../../api/api";
import { getTrimmedData } from "../../util";
import OffersDetails from "../Dialogs/OffersDetails";
import withTimedDisplay from "../../utils/timedAlert";

const Deals = (props) => {
  const { paginationModel, value, setPaginationModel } = props;
  const [error, setError] = useState(null);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const theme = useTheme();
  const TimedAlert = withTimedDisplay(Alert);
  const [showAlert, setShowAlert] = useState(false);

  const fetchProducts = async (type) => {
    setLoading(true);
    try {
      const response = await getAllProducts(
        paginationModel?.page + 1,
        paginationModel?.pageSize,
        "DESC",
        "",
        type
      );
      setProducts(response.data.products);
      setTotalCount(response.data.totalCount);
    } catch (err) {
      if (err.response && err.response.data) {
        if (
          err.response.data.display_errors &&
          err.response.data.display_errors.length > 0
        ) {
          setError(err.response.data.display_errors[0]);
          setShowAlert(true);
        } else {
          setError("An unexpected error occurred.");
          setShowAlert(true);
        }
      } else {
        setError("An unexpected error occurred.");
        setShowAlert(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleViewClick = (offer) => {
    setSelectedOffer(offer);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedOffer(null);
  };

  useEffect(() => {
    fetchProducts(value);
  }, [value, paginationModel?.page, paginationModel?.pageSize]);

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : products.length > 0 ? (
        <div className="product_scroll flex gap-2 w-full overflow-x-auto flex-row">
          {products?.map((prod, index) => (
            <div className="mb-2 min-w-[250px] relative">
              <div className="w-full flex flex-col items-center lg:max-w-full lg:flex border border-gray-200 rounded-md text-left">
                <a
                  className="block w-full"
                  key={index}
                  href={prod.productLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div
                    className="h-36 cursor-pointer sm:h-36 lg:h-36  w-full bg-cover rounded-l text-center bg-no-repeat"
                    style={{
                      backgroundImage: `url(${prod.photo})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                    title={prod.title}
                  ></div>
                </a>
                <div className="bg-white rounded-r lg:rounded-r-none p-4 flex flex-col justify-between leading-normal w-full ">
                  <div onClick={() => handleViewClick(prod)} className="my-2">
                    <h6 className="font-semibold text-sm cursor-pointer">
                      {" "}
                      {getTrimmedData(prod.title, 25)}
                    </h6>
                  </div>
                  <div className="my-1 flex gap-2 justify-between text-sm">
                    <div className="mt-1 flex gap-2">
                      <h6 className="font-semibold">{prod.discountPrice}</h6>
                      <h6 className="line-through text-red-600">
                        {prod.originalPrice}
                      </h6>
                    </div>
                    {prod.percentageOff !== "-" && (
                      <div
                        style={{
                          background: theme.palette.background.kimchi,
                          borderRadius: "4px",
                        }}
                        className={`w-fit px-3 py-0.5  text-white text-sm`}
                      >
                        {prod.percentageOff || "0%"} Off
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div
                style={{
                  background: "#FBDBCC",
                  border: `1px solid ${theme.palette.background.kimchi}`,
                }}
                className="absolute top-1 left-2 w-8 h-8 text-black rounded-full border z-20 flex justify-center items-center"
              >
                <span>
                  {prod.newDealsRanking > 0
                    ? prod.newDealsRanking
                    : prod.hotDealsRanking > 0
                      ? prod.hotDealsRanking
                      : ""}
                </span>
              </div>
            </div>
          ))}
          <OffersDetails
            open={dialogOpen}
            onClose={handleCloseDialog}
            offer={selectedOffer}
          />
          {/* <TablePagination
            rowsPerPageOptions={[3, 5, 10]}
            component="div"
            count={totalCount}
            rowsPerPage={paginationModel?.pageSize}
            page={paginationModel?.page}
            onPageChange={(event, newPage) => {
              setPaginationModel({ ...paginationModel, page: newPage });
            }}
            onRowsPerPageChange={(event) => {
              setPaginationModel({
                page: 0,
                pageSize: parseInt(event.target.value, 10),
              });
            }}
          /> */}
        </div>
      ) : (
        <>{<Alert severity="error">No Offers Found</Alert>}</>
      )}
    </>
  );
};

export default Deals;

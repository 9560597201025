import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Grid,
  useTheme,
  Alert,
} from "@mui/material";
import { useParams } from "react-router";
import Header from "../../components/Header/Header";
import {
  CancelOutlinedIcon,
  EditOutlinedIcon,
  ListOutlinedIcon,
  LocalOfferOutlinedIcon,
} from "../../Icons";
import { editProductById, getAllCategory, getProductById } from "../../api/api";
import { transformOfferData } from "../../constant";
import { Link } from "react-router-dom";
import withTimedDisplay from "../../utils/timedAlert";

const EditOffers = () => {
  const { id } = useParams();
  const theme = useTheme();
  const [category, setCategory] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    photo: "",
    category: "",
    show_in_hot_deals: 0,
    hot_deals_ranking: 1,
    show_in_new_deals: 0,
    new_deals_ranking: 1,
    original_price: "",
    discount_price: "",
    merchant: "",
    product_link: "",
    percentage_off: "",
  });
  const TimedAlert = withTimedDisplay(Alert);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    const fetchOfferDetails = async () => {
      try {
        const response = await getProductById(id);
        const offerData = response.data;
        const transformedData = offerData;
        setFormData(transformedData);
      } catch (err) {
        if (err.response && err.response.data) {
          if (
            err.response.data.display_errors &&
            err.response.data.display_errors.length > 0
          ) {
            setError(err.response.data.display_errors[0]);
            setShowAlert(true);
          } else {
            setError("An unexpected error occurred.");
            setShowAlert(true);
          }
        } else {
          setError("An unexpected error occurred.");
          setShowAlert(true);
        }
      }
    };

    fetchOfferDetails();
  }, [id]);

  const handleError = (err) => {
    if (err.response && err.response.data) {
      if (
        err.response.data.display_errors &&
        err.response.data.display_errors.length > 0
      ) {
        setError(err.response.data.display_errors[0]);
        setShowAlert(true);
      } else {
        setError("An unexpected error occurred.");
        setShowAlert(true);
      }
    } else {
      setError("An unexpected error occurred.");
      setShowAlert(true);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await getAllCategory(false);
      setCategory(response.data.category);
    } catch (err) {
      handleError(err);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => {
      if (type === "checkbox") {
        const updatedState = {
          ...prevState,
          [name]: checked ? 1 : 0,
        };

        if (name === "show_in_hot_deals" && checked) {
          updatedState.show_in_new_deals = 0;
        } else if (name === "show_in_new_deals" && checked) {
          updatedState.show_in_hot_deals = 0;
        }
        return updatedState;
      }

      if (name === "hot_deals_ranking" || name === "new_deals_ranking") {
        return {
          ...prevState,
          [name]: value ? Number(value) : "",
        };
      }

      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      await editProductById(id, formData);
      setSuccess("Offers Updated Successfully");
      setShowAlert(true);
    } catch (err) {
      if (err.response && err.response.data) {
        if (
          err.response.data.display_errors &&
          err.response.data.display_errors.length > 0
        ) {
          setError(err.response.data.display_errors[0]);
          setShowAlert(true);
        } else {
          setError("An unexpected error occurred.");
          setShowAlert(true);
        }
      } else {
        setError("An unexpected error occurred.");
        setShowAlert(true);
      }
    }
  };

  const breadcrumbsLinks = [
    {
      id: 1,
      title: "Edit Offer",
      href: `/offers/${id}`,
      icon: <LocalOfferOutlinedIcon fontSize="small" />,
    },
  ];
  const handleClear = () => {
    setFormData({
      title: "",
      description: "",
      photo: "",
      category: "",
      show_in_hot_deals: 0,
      hot_deals_ranking: "",
      show_in_new_deals: 0,
      new_deals_ranking: "",
      original_price: "",
      discount_price: "",
      merchant: "",
      product_link: "",
      percentage_off: "",
    });
    setSuccess(null);
  };

  return (
    <div>
      <Box m="0rem 2.5rem">
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Header breadcrumbsLinks={breadcrumbsLinks} />
          <Link to={`/offers`}>
            <Button
              startIcon={<ListOutlinedIcon />}
              sx={{
                mx: 1,
                py: 1,
                background: theme.palette.primary[500],
                color: "white",
              }}
              variant="contained"
            >
              List Offer
            </Button>
          </Link>
        </Box>

        <form onSubmit={handleSubmit}>
          {error && showAlert && (
            <TimedAlert setShowAlert={setShowAlert} severity="error">
              {error}
            </TimedAlert>
          )}
          {success && showAlert && (
            <TimedAlert setShowAlert={setShowAlert} severity="success">
              {success}
            </TimedAlert>
          )}
          <Grid container spacing={2} sx={{ mb: 3, mt: 1 }}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Photo URL"
                name="photo"
                value={formData.photo}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
              />
            </Grid>

            {/* Aligning Merchant, Category, and Product Link in a single row */}
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Merchant"
                  name="merchant"
                  value={formData.merchant}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth margin="normal" required>
                  <InputLabel>Category</InputLabel>
                  <Select
                    sx={{ textAlign: "left" }}
                    name="category"
                    label="Category"
                    value={formData.category}
                    onChange={handleChange}
                  >
                    {category &&
                      category.map((category) => (
                        <MenuItem key={category.id} value={category.name}>
                          {category.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Product Link"
                  name="product_link"
                  value={formData.product_link}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                fullWidth
                multiline
                rows={4}
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Original Price"
                  name="original_price"
                  value={formData.original_price}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Discount Price"
                  name="discount_price"
                  value={formData.discount_price}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Percentage Off"
                  name="percentage_off"
                  value={formData.percentage_off}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                />
              </Grid>
            </Grid>

            <Grid container item>
              <div className="label-bold">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="show_in_hot_deals"
                      checked={formData.show_in_hot_deals === 1}
                      onChange={handleChange}
                    />
                  }
                  label="Show in Hot Deals"
                />
              </div>
              <div className="label-bold">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="show_in_new_deals"
                      checked={formData.show_in_new_deals === 1}
                      onChange={handleChange}
                    />
                  }
                  label="Show in New Deals"
                />
              </div>
              {formData.show_in_hot_deals === 1 && (
                <Grid item xs={12}>
                  <TextField
                    label="Hot Deals Ranking"
                    name="hot_deals_ranking"
                    type="number"
                    value={formData.hot_deals_ranking}
                    onChange={handleChange}
                    fullWidth
                    required
                    margin="normal"
                    inputProps={{ min: 0, max: 10 }}
                  />
                </Grid>
              )}
              {formData.show_in_new_deals === 1 && (
                <Grid item xs={12}>
                  <TextField
                    label="New Deals Ranking"
                    name="new_deals_ranking"
                    type="number"
                    value={formData.new_deals_ranking}
                    onChange={handleChange}
                    fullWidth
                    required
                    margin="normal"
                    inputProps={{ min: 0, max: 10 }}
                  />
                </Grid>
              )}
            </Grid>

            <Grid item xs={12}>
              <Grid container justifyContent="right">
                <Button
                  startIcon={<EditOutlinedIcon />}
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    py: 1,
                    mt: 2,
                    mr: 2,
                    background: theme.palette.primary[500],
                    color: "white",
                  }}
                >
                  Update Offer
                </Button>

                <Button
                  onClick={handleClear}
                  startIcon={<CancelOutlinedIcon />}
                  variant="contained"
                  color="primary"
                  sx={{
                    py: 1,
                    mt: 2,
                    background: theme.palette.primary[800],
                    color: "white",
                  }}
                >
                  Clear
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Box>
    </div>
  );
};

export default EditOffers;
